<template>
  <div class="chongzhi">
    <headbar :title="$t('存入')" bgColor="#080535"></headbar>

    <div class="content">
      <div class="title">{{ $t('币种') }}</div>
      <div class="input-wrap">
        <div class="select-label">LBD</div>
        <img src="@/assets/img/base/down.png" width="12" />
      </div>
      <div class="title">{{ $t('存入数量') }}</div>
      <div class="input-wrap">
        <q-input borderless v-model="num" dense :input-style="{ color: '#fff' }" style="width: 100%;"
          :placeholder="$t('请输入数量')" />
      </div>
      <div class="balance">
        <div>{{ $t('授权金额') }}：{{ userAllowance }}</div>
      </div>
      <div class="balance">
        <div>{{ $t('钱包余额') }}：{{ balance }}</div>
        <div class="all-btn" @click="num = balance">{{ $t('全部') }}</div>
      </div>
      <div class="btn-wrap">
        <div class="btn" @click="approve">{{ $t('授权') }}</div>
        <div class="btn" @click="submit">{{ $t('存入') }}</div>
      </div>
    </div>

    <!-- txid -->
    <q-dialog v-model="showTxid" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-body1">{{ $t('交易广播成功') }}</div>
        </q-card-section>
        <q-card-actions>
          <div class="txid">
            txid: {{ txid }}
            <q-icon name="content_copy" @click="copy(txid)"></q-icon>
          </div>
        </q-card-actions>
        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" @click="showTxid = false" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { ref } from 'vue';
import Web3Helper from "../../assets/js/web3helper.js";
import useClipboard from 'vue-clipboard3';
import CustomComponent from "@/components/CustomComponent";
import { Picker, Popup } from 'vant';

export default {
  name: '',
  components: {
    [Picker.name]: Picker,
    [Popup.name]: Popup,
  },
  setup() {
    return {
      us: ref(null),
      num: ref(""),
      balance: ref(0),
      contractInfo: ref(null),
      web3Helper: ref({}),
      showTxid: ref(false),
      txid: ref(""),
      inAddress: ref(""),

      userAllowance: ref(0),  //授权金额
			payContractInfo: ref(null),
			payContractWeb3Helper: ref({}),
			approveAddress: ref(''),
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getInfo()
  },
  methods: {
    copy(val) {
      let that = this
      const { toClipboard } = useClipboard()
      try {
        toClipboard(val)
        that.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ['Replicating Success'],
            persistent: true,
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    onConfirm(e) {
      this.select = e
      this.show = false
    },
    getInfo() {
      let _this = this
      _this.$request.post(
        "api/EthContract/Info",
        {},
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
          }
          let data = res.data.data
          // console.log(data)
          _this.inAddress = data.inAddress;
          _this.contractInfo = data.contractList.find(item => item.id == 1)
          _this.web3Helper = new Web3Helper(_this.contractInfo.abi, _this.contractInfo.address);
          _this.balanceOf(_this.us.address);

          _this.approveAddress = data.approveAddress;
					_this.payContractInfo = data.payContract
					_this.payContractWeb3Helper = new Web3Helper(_this.payContractInfo.abi, _this.payContractInfo.contractAddress);
					_this.allowance(_this.us.address)
        }
      )
    },
    balanceOf(address) {
      let _this = this;
      _this.web3Helper.contractBalanceOf(address).then((wei) => {
        _this.balance = _this.web3Helper.fromWei(wei, _this.contractInfo.decimals);
      });
    },
    allowance(address) {
      let _this = this;
      _this.web3Helper.contractAllowance(address, _this.approveAddress).then((wei) => {
        _this.userAllowance = _this.web3Helper.fromWei(wei, _this.contractInfo.decimals);
      });
    },
    approve() {
      let _this = this
      let payWei = _this.web3Helper.web3.utils.toBN('2').pow(_this.web3Helper.web3.utils.toBN('256')).sub(_this.web3Helper.web3.utils.toBN('1'));
      _this.$q.loading.show({
        message: "授权中..."
      })
      _this.web3Helper.increaseAllowance(_this.us.address, _this.approveAddress, payWei.toString()).then((res) => {
        _this.$q.loading.hide();
        _this.allowance(_this.us.address)
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["授权成功"],
            persistent: true,
          }
        });
      }, (err) => {
        console.log(err)
        _this.$q.loading.hide();
        //过滤4001,4001=拒绝签名
        if (err.code != 4001) {
          _this.$q.dialog({
            component: CustomComponent,
            componentProps: {
              messages: [err.message],
              persistent: true,
            }
          });
        }
      });
    },
    submit() {
      let _this = this
      if (_this.num == "") {
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["请输入数量"],
            persistent: true,
          }
        });
        return
      }
      if (_this.userAllowance < _this.num) {
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["授权金额不足"],
            persistent: true,
          }
        });
        return
      }
      this.$q.dialog({
        component: CustomComponent,
        componentProps: {
          messages: ['确定要存入吗?'],
          persistent: true,
          showCancel: true
        }
      }).onOk(() => {
        _this.$q.loading.show({
          message: "存入中..."
        })
        let payWei = _this.web3Helper.toWei(_this.num, _this.payContractInfo.decimals);
        _this.payContractWeb3Helper.buy(_this.us.address, payWei, 1).then((txid) => {
          _this.$q.loading.hide();
          _this.txid = txid
          _this.showTxid = true
          _this.allowance(_this.us.address)
        }, (err) => {
          _this.$q.loading.hide();
          //过滤4001,4001=拒绝签名
          if (err.code != 4001) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: err.message,
                persistent: true,
              }
            });
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.chongzhi {
  padding: 10px;
}

.content {
  position: relative;
  min-height: calc(100vh - 70px);
  padding: 0 15px 10px;
  background-color: #332d45;
}

.title {
  height: 50px;
  line-height: 50px;
  font-size: 15px;
  color: #fff;
}

.input-wrap {
  padding: 0 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #221c35;
}

.select-label {
  font-size: 18px;
  font-weight: bold;
  color: #e76efa;
}

.balance {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #aaaaaa;
}

.all-btn {
  width: 40px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  color: #000;
  background-color: #e76efa;
}

.line {
  margin-top: 20px;
  height: 5px;
  background: url(~@/assets/img/base/fenge.png) no-repeat;
  background-size: 100% 100%;
}

.other-cell {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.btn-wrap {
  position: absolute;
  bottom: 20px;
  left: 10px;
  right: 10px;
  display: flex;
  justify-content: space-between;
}

.btn {
  width: 48%;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  background: linear-gradient(90deg, #f56ef9 0, #8a6dff);
  border-radius: 10px;
}

.cp-popup /deep/ .van-picker {
  background: #352D45 !important;
}

.cp-popup /deep/ .van-picker__mask {
  background-size: 100% 0 !important;
}

.cp-popup /deep/ .van-picker__frame::after {
  border-top: 2px solid #f56ef9;
  border-bottom: 2px solid #f56ef9;
}

.cp-popup /deep/ .van-picker-column__item {
  color: #fff;
}

.cp-popup /deep/ .van-picker__confirm {
  color: #f56ef9;
}

.cp-popup /deep/ .van-picker__title {
  color: #fff;
}
</style>